<template>
	<div>
		<!-- 图片 -->
		<div>
			<el-image
			    class="in_02 w100 display-block"
			      :src="bg"
			     fit="fit"></el-image>
		</div>

		<div class="center flex-column">
			<div class="w1200">
				<div class="h100px align-items-center fz40 co-333333">竞赛信息</div>
			</div>
			<!-- 列表 -->
			<div class="w100 bg-F7F7F7 center flex-column">
				<div class="w1200">
					<div class="justify-content-center-between paddt20 paddb40">
						<div class="align-items-center">
							<span class="fz20 co-333333" style="width: 70px;">筛选</span>
							<div class="display-flex flex-wrap">
							<div class="title-item center cure-pointer fz14" v-for="(item, i) in selectList" :key="i"
								:class="[titleIndex == i ? 'bg-DCFF03 co-010085' : 'co-333333', i == 0 ? 'marl10' : '']"
								@click="titleChoose(i)">{{item.name}}</div>
							</div>	
						</div>

						<div class="center sort cure-pointer relative" @click="pullClick">
							<span class="fz14 co-010085"  style="width: 70px;">{{titleListfont}}</span>
							<img class="pull-pic w100 marl4 display-block" :class="pull ? 'rotate' : ''"
								src="@/assets/img/pull.png" alt="">
							<img class="triangle display-block absolute" v-if="pull" src="@/assets/img/triangle.png"
								alt="" />
							<div class="absolute pull-box z100" :style="`height: ${pull ? '130px' : '0px'};`">
								<div class="justify-content-center-between paddlr12 h40" v-for="(item, i) in titleList"
									:key="i" @click.stop="selectChoose(i)">
									<span class="fz14"
										:class="seletIndex == i ? 'co-010085' : 'co-333333'">{{item}}</span>
									<img class="check_icon display-block" v-if="seletIndex == i"
										src="@/assets/img/check_icon.png" alt="" />
								</div>
							</div>
						</div>
					</div>
					<div style="min-height: 1120px;">
					<Competition :list='list'></Competition>
						
					<loadMore :state="state" loading_icon></loadMore>
					</div>
					<div class="center paddt54 paddb15">		
						<el-pagination background  class="center "  @current-change="handleCurrentChange"
						    layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
			<div v-loading.fullscreen.lock="loading"></div>
	</div>
</template>

<script>
	export default {
		name: "InspirationPart",
		data() {
			return {
				titleList: ['综合', '最新', '最热'], // 筛选标题
				titleIndex: 0, // 筛选标题选中
				pull: false, // 是否下拉
				selectList: [], // 下拉列表
				seletIndex: '', // 下拉选中
				state: 1, // 列表加载状态
				page: 1, // 分页
				loading: false, // 接口加载状态
				list: [], // 假分页数据		
				size: 16,
				dataObj:{
					tag_id:0,
					reco:'',
					sort:'',
				},
				classroomlist: [],
				bg:'',
				maxnum:'',
				titleListfont:"综合排序"
			}
		},
		mounted() {
			this.$req({
				method: 'get',
				data: {
					type: 'competition'
				},
				url: '/api/tags',
				success: res => {
					this.selectList = res;
					this.selectList.unshift({name:'全部',id:0})
				},
				fail: error => {

				}
			});
			this.$req({
				method: 'get',
				url: '/api/config',
				data:{
					name:'competition_banner_image'
				},
				success: res => {
					this.bg = res.competition_banner_image;
				
				},
				fail: error => {}
			});
			
			this.getlist();
		},
		methods: {
			getlist() {
				if (this.loading || this.state > 1) return;
				let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 16;
				this.$req({
					method: 'get',
					data: {
						type: 'competition',
						...this.dataObj,
						page: this.page,
						size: size,
					},
					url: '/api/article',
					success: res => {
						let list = res;
						if (page == 1) {
							this.list = list;
						} else {
							this.list= this.list.concat(list);
						}
						// this.paging();
						if (list.length > 0) {
						    this.state = 0;
						} else {
						    this.state = 3;
						}
						this.loading = false;
						
					},
					fail: error => {
						this.loading = false;
						this.state = 3;
					}
				});
				this.$req({
					method: 'get',
					data: {
						type: 'competition',
						...this.dataObj,
						show_page:1,
						
					},
					url: '/api/article',
					success: res => {
						console.log(res,'总条数')
						this.maxnum =res;
					},
					fail: error => {
						
					}
				});
			},
			// 假分页
			paging() {
			    let all = this.classroomlist;
			    let size = this.size;
			    let index = (this.page - 1) * size;
			    let arr = [];
			    for (let i = 0; i < size; i++) {
			        if (index < all.length) {
			            arr.push(all[index])
			        }
			        index++;
			    }
			    this.list = arr;
			},
			// 筛选标题选中
			titleChoose(e) {
				this.titleIndex = e;
				this.dataObj.tag_id = this.selectList[e].id
				this.state = 0;
				this.getlist()
			},
			// 点击下拉
			pullClick() {
				this.pull = !this.pull
			},
			// 下拉选中
			selectChoose(e) {
				this.seletIndex = e;
				this.titleListfont = this.titleList[this.seletIndex]
				this.dataObj.sort = e+1;
				this.state = 0;
				this.list=[];
				this.getlist();
				this.pullClick();
			},
			// 点击分页改变页码
			handleCurrentChange(val) {
			    this.page = val;
			    this.list = [];
			    this.loading = false;
			    this.getlist();
			    document.documentElement.scrollTop = document.body.scrollTop = 400;
			},
		}
	}
</script>

<style lang="less" scoped>
	.in_02 {
		height: 200px;
	}

	/* 筛选标题 */
	.title-item {
		min-width: 70px;
		padding: 0 5px;
		height: 28px;
		border-radius: 3px;
		margin-bottom:8px;
	}

	/* 排序 */
	.sort {
		width: 90rpx;
		height: 28px;
		border: 1px solid #010085;
		border-radius: 3px;
		padding: 0 8px;

		.pull-pic {
			width: 14px;
			height: 14px;
			transition: all 0.3s;
		}

		.rotate {
			transform: rotate(180deg);
		}

		.triangle {
			width: 27px;
			height: 15px;
			top: 28px;
			right: 0;
			z-index: 100;
		}

		.pull-box {
			width: 140px;
			background: #FFFFFF;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
			top: 43px;
			border-radius: 8px;
			left: 0;
			overflow: hidden;
			transition: all 0.3s;

			.check_icon {
				width: 15px;
				height: 15px;
			}
		}
	}

	// 改变组件样式
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999 !important;
	}

	.btn-prev,
	.btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}

	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}
</style>
